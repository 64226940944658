import styles from './Registration.module.css';
import { useFormik } from "formik";
import {useEffect, useState, useRef} from "react";
import axios from "axios";
import * as Yup from 'yup';
import {Oval} from "react-loader-spinner";
import emailjs, { init } from "@emailjs/browser";

const errorMessages = {
    short: 'Замало символів :(',
    long: 'Забагато символів :(',
    email: 'Вкажіть правильну електронну пошту',
    number: 'Вкажіть правильний номер телефону',
    req: 'Це обов\'язкове поле'
}

const RegSchema = Yup.object().shape({
    name: Yup.string()
        .min(2, errorMessages.short)
        .max(50, errorMessages.long)
        .required(errorMessages.req),
    surname: Yup.string()
        .min(2, errorMessages.short)
        .max(50, errorMessages.long)
        .required(errorMessages.req),
    father: Yup.string()
        .min(2, errorMessages.short)
        .max(50, errorMessages.long)
        .required(errorMessages.req),
    number: Yup.string()
        .min(2, errorMessages.short)
        .max(50, errorMessages.long)
        .required(errorMessages.req),
    email: Yup.string().email(errorMessages.email).required(errorMessages.req),
    // pay: Yup.string()
    //     .min(2, errorMessages.short)
    //     .required(errorMessages.req),
});

export default function Registration() {

    const initialValues = {
        name: '',
        surname: '',
        father: '',
        number: '',
        email: '',
        pay: '',
        math: null,
        eng: null,
        ukr: null,
        ukrLite: null,
        hist: null,
        geo: null,
        bio: null,
        fiz: null,
        paint: null
    }
    const [price, setPrice] = useState(0);
    const [modal, setModal] = useState(false);
    const [showButton, setShowButton] = useState(true);
    const formRef = useRef(null);

    const onSubmit = async values => {
        setShowButton(false);
        const {name, surname, father, number, email, math, eng, ukr, ukrLite, hist, geo, bio, fiz, paint} = values;
        const scriptUrl = 'https://script.google.com/macros/s/AKfycbwxwTrGFQvi2a6XMuAycbUOjJKUs3X-w32gU9Lhti7FVZT03vynI-e0CrDCAkDyfmz5/exec';

        await fetch(scriptUrl, {
            method: 'POST',
            body: new FormData(formRef.current)
        }).then(() => {
        })

        await emailjs.sendForm('service_w7qvs1t', 'template_2gsxymi', formRef.current, 'GooQNe3LAR_UTkJbE');
        formik.resetForm();
        setModal(true);
        setShowButton(true);
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: RegSchema,
        onSubmit: values => onSubmit(values),
    });

    useEffect(() => {
        const entries = Object.keys(formik.values).map(item => formik.values[item]);
        const small = entries.filter(item => item === 'college').length * 3000;
        const big = entries.filter(item => item === 'big').length * 1500;
        const paint = entries.filter(item => item === 'paint').length * 3000;
        setPrice(small + big + paint);
    }, [formik.values])

    return (
        <div className={styles.regWrapper}>
            <h2>Реєстрація</h2>
            <div className={styles.warn}>
                <b>Важливо! Оплата здійснюється ПІСЛЯ формування груп, про що буде повідомлено окремо</b>
            </div>
            <div id='registration'></div>
            {
                modal ? <div className={styles.modalWrapper}>
                    <div className={styles.modal}>
                        <span className={styles.name}>Заяву надіслано</span>
                        <span className={styles.warn}>
                            Ви зареєструвались. Після того, як будуть сформовані групи, ви отримаєте повідомлення з запрошенням долучитись до груп в телеграм-каналі.</span>
                        <div className={styles.closeButton} onClick={() => setModal(false)}>x Закрити</div>
                    </div>
                </div> : ''
            }

            <form onSubmit={formik.handleSubmit} ref={formRef}>
                <div className={styles.fields}>
                    <div className={styles.column}>
                        <span className={styles.subName}>Як тебе звати?</span>
                        <span className={styles.formError}>{formik.touched.surname && formik.errors.surname ?
                            <span>{formik.errors.surname}</span> : ''}</span>
                        <input
                            className={styles.input}
                            type="text"
                            name='surname'
                            onChange={formik.handleChange}
                            value={formik.values.surname}
                            placeholder='Прізвище'
                        />
                        <span className={styles.formError}>{formik.touched.name && formik.errors.name ?
                            <span>{formik.errors.name}</span> : ''}</span>
                        <input
                            className={styles.input}
                            type="text"
                            name='name'
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            placeholder='Ім’я'
                        />
                        <span className={styles.formError}>{formik.touched.father && formik.errors.father ?
                            <span>{formik.errors.father}</span> : ''}</span>
                        <input
                            className={styles.input}
                            type="text"
                            name='father'
                            onChange={formik.handleChange}
                            value={formik.values.father}
                            placeholder='По батькові'
                        />
                        <span className={styles.subName}>Твої контакти</span>
                        <span className={styles.formError}>{formik.touched.number && formik.errors.number ?
                            <span>{formik.errors.number}</span> : ''}</span>
                        <input
                            className={styles.input}
                            type="text"
                            name='number'
                            onChange={formik.handleChange}
                            value={formik.values.number}
                            placeholder='Номер телефону'
                        />
                        <span className={styles.formError}>{formik.touched.email && formik.errors.email ?
                            <span>{formik.errors.email}</span> : ''}</span>
                        <input
                            className={styles.input}
                            type="text"
                            name='email'
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            placeholder='Електронна пошта'
                        />
                        {/*<span className={styles.subName}>Оплата</span>*/}
                        {/*<input*/}
                        {/*    className={styles.input}*/}
                        {/*    type="text"*/}
                        {/*    name='pay'*/}
                        {/*    onChange={formik.handleChange}*/}
                        {/*    value={formik.values.pay}*/}
                        {/*    placeholder='Номер квитації про оплату'*/}
                        {/*/>*/}
                    </div>
                    <div className={styles.column}>
                        <div className={styles.lesson} style={{fontWeight: 600, marginBottom: '60px'}}>
                            <span className={styles.name}>Предмет</span>
                            <div><span>Не обрано</span></div>
                            <div><span>Велика група</span></div>
                            {/*<div><span>Маленька група</span></div>*/}
                        </div>
                        <div className={styles.lesson}>
                            <span className={styles.name}>Математика</span>
                            <input type="radio" name="math" value={null} onChange={formik.handleChange}
                                   defaultChecked={true}/>
                            <input type="radio" name="math" value='big' onChange={formik.handleChange}/>
                            {/*<input type="radio" name="math" value='college' onChange={formik.handleChange}/>*/}
                        </div>
                        <div className={styles.lesson}>
                            <span className={styles.name}>Англійська мова</span>
                            <input type="radio" name="eng" value={null} onChange={formik.handleChange}
                                   defaultChecked={true}/>
                            <input type="radio" name="eng" value='big' onChange={formik.handleChange}/>
                            {/*<input type="radio" name="eng" value='college' onChange={formik.handleChange}/>*/}
                        </div>
                        <div className={styles.lesson}>
                            <span className={styles.name}>Українська мова</span>
                            <input type="radio" name="ukr" value={null} onChange={formik.handleChange}
                                   defaultChecked={true}/>
                            <input type="radio" name="ukr" value='big' onChange={formik.handleChange}/>
                            {/*<input type="radio" name="ukr" value='college' onChange={formik.handleChange}/>*/}
                        </div>
                        {/*<div className={styles.lesson}>*/}
                        {/*    <span className={styles.name}>Українська література</span>*/}
                        {/*    <input type="radio" name="ukrLite" value={null} onChange={formik.handleChange}*/}
                        {/*           defaultChecked={true}/>*/}
                        {/*    <input type="radio" name="ukrLite" value='big' onChange={formik.handleChange}/>*/}
                        {/*    /!*<input type="radio" name="ukrLite" value='small' onChange={formik.handleChange} />*!/*/}
                        {/*</div>*/}
                        <div className={styles.lesson}>
                            <span className={styles.name}>Історія України</span>
                            <input type="radio" name="hist" value={null} onChange={formik.handleChange}
                                   defaultChecked={true}/>
                            <input type="radio" name="hist" value='big' onChange={formik.handleChange}/>
                            {/*<input type="radio" name="hist" value='college' onChange={formik.handleChange}/>*/}
                        </div>
                        {/*<div className={styles.lesson}>*/}
                        {/*    <span className={styles.name}>Географія</span>*/}
                        {/*    <input type="radio" name="geo" value={null} onChange={formik.handleChange} defaultChecked={true}/>*/}
                        {/*    <input type="radio" name="geo" value='big' onChange={formik.handleChange} />*/}
                        {/*    <input type="radio" name="geo" value='small' onChange={formik.handleChange} />*/}
                        {/*</div>*/}
                        {/*<div className={styles.lesson}>*/}
                        {/*    <span className={styles.name}>Біологія</span>*/}
                        {/*    <input type="radio" name="bio" value={null} onChange={formik.handleChange} defaultChecked={true}/>*/}
                        {/*    <input type="radio" name="bio" value='big' onChange={formik.handleChange} />*/}
                        {/*    <input type="radio" name="bio" value='small' onChange={formik.handleChange} />*/}
                        {/*</div>*/}
                        {/*<div className={styles.lesson}>*/}
                        {/*    <span className={styles.name}>Фізика</span>*/}
                        {/*    <input type="radio" name="fiz" value={null} onChange={formik.handleChange}*/}
                        {/*           defaultChecked={true}/>*/}
                        {/*    <input type="radio" name="fiz" value='big' onChange={formik.handleChange}/>*/}
                        {/*    /!*<input type="radio" name="fiz" value='college' onChange={formik.handleChange} />*!/*/}
                        {/*</div>*/}
                        <div className={styles.lesson}>
                            <span className={styles.name}>Рисунок та композиція (група до 15 осіб)</span>
                            <input type="radio" name="paint" value={null} onChange={formik.handleChange}
                                   defaultChecked={true}/>
                            <input type="radio" name="paint" value='paint' onChange={formik.handleChange}
                                   disabled={false}/>
                            {/*<input type="radio" name="paint" value='paint' onChange={formik.handleChange}/>*/}
                        </div>
                    </div>
                </div>
                <div className={styles.result}>
                    <div className={styles.item}>
                        <span className={styles.name}>Сформована ціна</span>
                        <span className={styles.info}>{price} грн</span>
                    </div>
                    <div className={styles.item}>
                        <span className={styles.name}>Початок занять</span>
                        <span className={styles.info}>3 лютого</span>
                    </div>
                    <div className={styles.footer}>
                        <span>Натискаючи «Записатися на курси» ви надаєте згоду на збір, обробку та використання персональних даних</span>
                        {showButton ?
                            <button type='submit'><span>Записатися на курси</span>{!formik.isValid ?
                                <span className={styles.formError}>Є помилка у формі</span>
                                : ''}</button> :
                            <Oval
                                height={80}
                                width={80}
                                color="#fff"
                                visible={true}
                                ariaLabel='oval-loading'
                                secondaryColor="#fff000"
                                strokeWidth={2}
                                strokeWidthSecondary={2}

                            />
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}